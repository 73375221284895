import React, {useEffect, useState} from "react";
import './form_2.scss'
import axios from "axios";
import configs from "../../api";
import validation from "../../tools/validation";
import isEmpty from "lodash/isEmpty";
import _ from "lodash";
import toast from "../../components/Toast";
import {useParams} from "react-router-dom";
import moment from 'moment';
import MultiSelectAppliances from "../multi_selects/multi_appliances/multiSelectAppliances";
import MultiSelectBrands from "../multi_selects/multi_brands/multiSelectBrands";

export default function Form_2({setShow, load}) {
    const [errors, setErrors] = useState({});
    const [services, setServices] = useState([]);
    const [states, setStates] = useState([]);
    const [brands, setBrands] = useState([]);
    const [cities, setCities] = useState([]);
    const [city, setCity] = useState([]);
    const [submit, setSubmit] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    let {projectUrl} = useParams();
    let {color} = useParams();
    let {hoverColor} = useParams();

    const times = [
        {id: 1, title: "Morning"},
        {id: 2, title: "Noon"},
        {id: 3, title: "Afternoon"},
        {id: 4, title: "Evening"},
        {id: 5, title: "No Preference"},
    ];
    const [form, setForm] = useState({
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        states: "",
        state: "",
        city: "",
        address: "",
        zip_code: "",
        appliance: "",
        brand: "",
        service_date: "",
        service_time: "",
        description: "",
    });

    useEffect(() => {
        getAllServices();
        getAllStates();
        getAllCities();
        getBrands();
    }, [])

    const getAllServices = async () => {
        try {
            const resp = await axios.get(`${configs.adminApi}/select/appliances`);
            setServices(resp?.data?.appliance);
        } catch (err) {
            console.error(err);
        }
    };

    const getAllStates = async () => {
        try {
            const resp = await axios.get(`${configs.adminApi}/select/state`);
            setStates(resp?.data?.state);
        } catch (err) {
            console.error(err);
        }
    };

    const getAllCities = async () => {
        try {
            const resp = await axios.get(`${configs.adminApi}/select/cities`);
            setCities(resp?.data?.cities);
        } catch (err) {
            console.error(err);
        }
    };

    const getBrands = async () => {
        try {
            const resp = await axios.get(`${configs.adminApi}/select/brands`);
            setBrands(resp?.data?.brands);
        } catch (err) {
            console.error(err);
        }
    };

    const handleChange = (name, value) => {
        setForm( { ...form, [name]: value });
        const val = validation[name](value);
        if (val?.status !== "ok") {
            setErrors({...errors, [name]: value.errors});
        }else {
            setErrors({...errors, [name]: ""});
        }
        if (name == "state") {
            setCity(cities.filter((c) => c.state_id == value))
        }
    };
    const notify = React.useCallback((type, message) => {
        toast({type, message});
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        let err = {};
        setSubmit(true)
        for (const i in form) {
            if (validation[i]) {
                const v = validation[i](form[i]);
                err[i] = v.errors;
                if (v.status !== "ok") {
                    _.set(errors, i, v.errors);
                    setErrors({...errors});
                } else {
                    delete err[i];
                }
            }
        }
        if (moment(form.service_date).diff(moment(new Date()).add(1, 'years')) > 0) {
            notify('error', "The selected service date is too far. Please contact support.");
            return;
        }
        if (!_.isEmpty(err)) {
            notify("error", err[Object.keys(err)[0]]);
        }
        if (!isEmpty(err)) {
            return;
        }
        load(true);
        try {
            const adminResp = await axios.post(
                `${configs.adminApi}/messages/schedule`,
                {
                    form: {
                        ...form,
                        call_time: form.service_time,
                        service_time: " ",
                        source: projectUrl,
                        country: "United States",
                        stateId: form.state,
                    },
                },
            );
            if (adminResp.status === 200) {
                load(false);
                setShow(true);
                setSubmit(false)
                setForm({
                    first_name: "",
                    last_name: "",
                    email: "",
                    phone_number: "",
                    states: "",
                    state: "",
                    city: "",
                    address: "",
                    zip_code: "",
                    appliance: "",
                    brand: "",
                    service_date: "",
                    service_time: "",
                    description: "",
                });
            }
        } catch (err) {
            console.error(err);
        }
    };

    function addYears(date, years) {
        date.setFullYear(date.getFullYear() + years);
        return date;
    }

    // console.log(errors)

    const date = new Date();
    const newDate = addYears(date, 1);
    return (
        <form className="form" noValidate>
            <div className="form_block_container">
                <div className="form_block">
                    <h4>Personal Details</h4>
                    <div className="form_row">
                        <div className="input_block">
                            <label htmlFor="first_name">
                                Your First Name{" "}
                                <span style={{color: "red"}}>*</span>
                            </label>
                            <input
                                style={
                                    errors.first_name
                                        ? {border: "1px solid #AC1125"}
                                        : {border: "1px solid #B2B1B1FF"}
                                }
                                id="first_name"
                                className="inputEl"
                                type="text"
                                pattern="[^-][a-zA-Z -]{2,100}"
                                name="first_name"
                                value={form.first_name}
                                onChange={(e) => handleChange('first_name', e.target.value)}
                            />
                        </div>
                        <div className="input_block">
                            <label htmlFor="last_name">
                                Your Last Name{" "}
                                <span style={{color: "red"}}>*</span>
                            </label>
                            <input
                                style={
                                    errors.last_name
                                         ? {border: "1px solid #AC1125"}
                                        : {border: "1px solid #B2B1B1FF"}
                                }
                                value={form.last_name}
                                id="last_name"
                                className="inputEl"
                                type="text"
                                pattern="[^-][a-zA-Z -]{2,100}"
                                name="last_name"
                                onChange={(e) => handleChange('last_name', e.target.value)}
                            />
                        </div>
                        <div className="input_block">
                            <label htmlFor="email">
                                Email <span style={{color: "red"}}>*</span>
                            </label>
                            <input
                                style={
                                    errors.email
                                         ? {border: "1px solid #AC1125"}
                                        : {border: "1px solid #B2B1B1FF"}
                                }
                                value={form.email}
                                id="email"
                                className="inputEl"
                                type="text"
                                placeholder="example@example.com"
                                pattern="^[a-zA-Z0-9_\.\-]+@[a-zA-Z0-9\.-]+$"
                                name="email"
                                onChange={(e) => handleChange('email', e.target.value)}
                            />
                        </div>
                        <div className="input_block">
                            <label htmlFor="phone_number">
                                Phone Number{" "}
                                <span style={{color: "red"}}>*</span>
                            </label>
                            <input
                                style={
                                    errors.phone_number
                                         ? {border: "1px solid #AC1125"}
                                        : {border: "1px solid #B2B1B1FF"}
                                }
                                value={form.phone_number}
                                id="phone_number"
                                className="inputEl"
                                type="text"
                                pattern="\+?[()0-9- ]{4,25}"
                                name="phone_number"
                                onChange={(e) => handleChange('phone_number', e.target.value)}
                            />
                        </div>
                    </div>
                    <h4>Location Details</h4>
                    <div className="form_row">
                        <div className="input_block_address">
                            <label htmlFor="address">
                                Address <span style={{color: "red"}}>*</span>
                            </label>
                            <input
                                style={
                                    errors.address
                                         ? {border: "1px solid #AC1125"}
                                        : {border: "1px solid #B2B1B1FF"}
                                }
                                value={form.address}
                                id="address"
                                className="inputEl"
                                required
                                type="text"
                                name="address"
                                onChange={(e) => handleChange('address', e.target.value)}
                            />
                        </div>
                        <div className="input_block_zip">
                            <label htmlFor="zip_code">
                                Zip Code <span style={{color: "red"}}>*</span>{" "}
                            </label>
                            <input
                                style={
                                    errors.zip_code
                                         ? {border: "1px solid #AC1125"}
                                        : {border: "1px solid #B2B1B1FF"}
                                }
                                value={form.zip_code}
                                id="zip_code"
                                className="inputEl"
                                required
                                type="text"
                                pattern="[0-9]{1,10}"
                                name="zip_code"
                                onChange={(e) => handleChange('zip_code', e.target.value)}
                            />
                        </div>

                        <div className="input_block">
                            <label htmlFor="country">Country</label>
                            <input
                                id="country"
                                className="inputEl"
                                type="text"
                                pattern="[a-zA-Z0-9 \.,()]{2,100}"
                                name="country"
                                onChange={(e) => handleChange('country', e.target.value)}
                                disabled
                                defaultValue="United States"
                            />
                        </div>
                        <div className="input_block">
                            <label htmlFor="states">State <span style={{color: "red"}}>*</span></label>
                            <select
                                style={
                                    errors.state
                                         ? {border: "1px solid #AC1125"}
                                        : {border: "1px solid #B2B1B1FF"}
                                }
                                // required
                                value={form.state}
                                id="state"
                                className="inputEl"
                                onChange={(e) => handleChange('state', e.target.value)}
                                name="state"
                                data-key="states"

                            >
                                <option value="">-- Select --</option>
                                {states
                                    ? states?.map((item) => {
                                        return (
                                            <option
                                                key={item.id}
                                                value={item.id}
                                            >
                                                {item.name}
                                            </option>
                                        );
                                    })
                                    : null}
                            </select>
                        </div>
                        <div className="input_block">
                            <label htmlFor="city">
                                City <span style={{color: "red"}}>*</span>
                            </label>
                            <select
                                style={
                                    errors.city
                                         ? {border: "1px solid #AC1125"}
                                        : {border: "1px solid #B2B1B1FF"}
                                }
                                value={form.city}
                                id="city"
                                className="inputEl"
                                onChange={(e) =>
                                    handleChange('city', e.target.value)
                            }
                                name="city"
                                data-key="cities"
                                required
                            >
                                <option value="">-- Select --</option>
                                {city ? city?.map((item) => {
                                        return (
                                            <option
                                                key={item.id}
                                                value={item.name}
                                            >
                                                {item.name}
                                            </option>
                                        );
                                    })
                                    : null}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="form_block">
                    <h4>Service Details</h4>
                    <div className="form_row">
                        <div className="input_block">
                            <label htmlFor="appliance">
                                Appliance{" "}
                                <span style={{color: "red"}}>*</span>
                            </label>
                            <MultiSelectAppliances
                                validated={errors.appliance}
                                handleChange={handleChange}
                                appliances={services}
                                formData={form}
                                submit={submit}
                            />
                        </div>
                        <div className="input_block">
                            <label htmlFor="brand">
                                Brand <span style={{color: "red"}}>*</span>
                            </label>
                            <MultiSelectBrands
                                validated={errors.brand}
                                handleChange={handleChange}
                                brands={brands}
                                formData={form}
                                submit={submit}
                            />
                        </div>
                        {/*<div className="input_block">*/}
                        {/*	<label htmlFor="appliance">*/}
                        {/*		Appliance{" "}*/}
                        {/*		<span style={{ color: "red" }}>*</span>*/}
                        {/*	</label>*/}
                        {/*	<select*/}
                        {/*		style={*/}
                        {/*			errors.appliance*/}
                        {/*				? { border: "1px solid #AC1125" }*/}
                        {/*				: { border: "1px solid #626262" }*/}
                        {/*		}*/}
                        {/*		value={form.appliance}*/}
                        {/*		id="appliance"*/}
                        {/*		className="inputEl"*/}
                        {/*		onChange={(e) => handleChange(e)}*/}
                        {/*		name="appliance"*/}
                        {/*		data-key="appliances"*/}
                        {/*		required*/}
                        {/*	>*/}
                        {/*		<option value="">*/}
                        {/*			-- Select --*/}
                        {/*		</option>*/}

                        {/*		{services*/}
                        {/*			? services?.map((service) => {*/}
                        {/*				return (*/}
                        {/*					<option*/}
                        {/*						key={service.id}*/}
                        {/*						value={service.appliance_name}*/}
                        {/*					>*/}
                        {/*						{service.appliance_name}*/}
                        {/*					</option>*/}
                        {/*				);*/}
                        {/*			})*/}
                        {/*			: null}*/}
                        {/*	</select>*/}
                        {/*</div>*/}
                        {/*<div className="input_block">*/}
                        {/*	<label htmlFor="brand">*/}
                        {/*		Brand <span style={{ color: "red" }}>*</span>*/}
                        {/*	</label>*/}
                        {/*	<select*/}
                        {/*		style={*/}
                        {/*			errors.brand*/}
                        {/*				? { border: "1px solid #AC1125" }*/}
                        {/*				: { border: "1px solid #626262" }*/}
                        {/*		}*/}
                        {/*		value={form.brand}*/}
                        {/*		id="brand"*/}
                        {/*		className="inputEl"*/}
                        {/*		onChange={(e) => handleChange(e)}*/}
                        {/*		name="brand"*/}
                        {/*		data-key="brands"*/}
                        {/*		required*/}
                        {/*	>*/}
                        {/*		<option value="">-- Select --</option>*/}

                        {/*		{brands*/}
                        {/*			? brands.map((brand) => {*/}
                        {/*				return (*/}
                        {/*					<option*/}
                        {/*						key={brand.id}*/}
                        {/*						value={brand.brand_name}*/}
                        {/*					>*/}
                        {/*						{brand.brand_name}*/}
                        {/*					</option>*/}
                        {/*				);*/}
                        {/*			})*/}
                        {/*			: null}*/}
                        {/*		<option value="99">Other</option>*/}
                        {/*	</select>*/}
                        {/*</div>*/}
                        <div className="input_block">
                            <label htmlFor="service_date">
                                Service Date{" "}
                                <span style={{color: "red"}}>*</span>
                            </label>
                            <input
                                style={
                                    errors.service_date
                                         ? {border: "1px solid #AC1125"}
                                        : {border: "1px solid #B2B1B1FF"}
                                }
                                value={form.service_date}
                                id="service_date"
                                required
                                type="date"
                                name="service_date"
                                min={new Date().toISOString().split("T")[0]}
                                max={newDate.toISOString().split("T")[0]}
                                onChange={(e) => handleChange('service_date', e.target.value)}
                            />
                        </div>
                        <div className="input_block">
                            <label htmlFor="brand">
                                Best Time To Call
                                <span style={{color: "red"}}> *</span>
                            </label>
                            <select
                                style={
                                    errors.service_time
                                         ? {border: "1px solid #AC1125"}
                                        : {border: "1px solid #B2B1B1FF"}
                                }
                                value={form.service_time}
                                id="service_time"
                                className="inputEl"
                                onChange={(e) => handleChange('service_time', e.target.value)}
                                name="service_time"
                                data-key="service_times"
                                required
                            >
                                <option value="">-- Select a Time --</option>
                                {times
                                    ? times.map((time) => {
                                        return (
                                            <option
                                                key={time.id}
                                                value={time.title}
                                            >
                                                {time.title}
                                            </option>
                                        );
                                    })
                                    : null}
                            </select>
                        </div>
                    </div>
                    <h4></h4>
                    <div className="form_row">
                        <div className="input_block textarea_block">
                            <label htmlFor="description">
                                Brief Description of Issue{" "}
                                <span style={{color: "red"}}>*</span>
                            </label>
                            <textarea
                                style={
                                    errors.description
                                         ? {border: "1px solid #AC1125"}
                                        : {border: "1px solid #B2B1B1FF"}
                                }
                                value={form.description}
                                id="description"
                                className="inputEl"
                                as="textarea"
                                onChange={(e) => handleChange('description', e.target.value)}
                                name="description"
                                pattern="[^<]([^>]+)?[^>]"
                                rows={4}
                                required
                            ></textarea>
                        </div>
                    </div>
                </div>
                <div className="form_btn_group2">
                    <div
                        className="button_2_block"
                        onClick={(e) => handleSubmit(e)}
                    >
                        <button className="button_2"
                                style={{
                                    backgroundColor: isHovered ? `#${hoverColor}` : `#${color}`,
                                    transition: "background-color 0.3s ease",
                                    cursor: "pointer",
                                    color: 'white'
                                }}
                                onMouseEnter={() => {
                                    setIsHovered(true)
                                }}
                                onMouseLeave={() => {
                                    setIsHovered(false)
                                }}
                        >Schedule Service
                        </button>
                    </div>
                </div>
            </div>
        </form>
    );
}