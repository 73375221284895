import React, {useEffect, useState} from 'react'
import BrandSlider from "../components/brand-slider/brand-slider";
import axios from "axios";
import configs from "../api";
import Section_1 from "../components/section/Section_1";

export default function BrandsSlider() {
    const [allBrands, setAllBrands] = useState([]);

    useEffect(() => {
        getBrands()
    }, []);

    const getBrands = async () => {
        try {
            const resp = await axios.get(`${configs.api}/brands`, {
                params: {
                    db_name: `${configs.db_name}`,
                    user: `${configs.user}`,
                    password: `${configs.pass}`,
                },
            });
            setAllBrands(resp.data.brands);
        } catch (err) {
            console.error(err);
        }
    };
    return (
        <div className='wrapper'>
                <Section_1>
                    <div className="container">
                        <BrandSlider brands={allBrands}/>
                    </div>
                </Section_1>
        </div>
    );
}

