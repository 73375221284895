import React from "react";
import "./section_1.scss";

export default function Section_1({children, title}) {
    return (

        <div className="section_1">
            <div className="container">
                {title ? <h2>{title}</h2> : null}
                <div className="section_1_content">{children}</div>
            </div>
        </div>

    );
}
