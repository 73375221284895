import React from 'react';
import {TextField, Autocomplete, MenuItem} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import {Form} from 'react-bootstrap';
import '../multi_brands/multi_brands.scss';

export default function MultiSelectBrands({
                                              validated,
                                              handleChange,
                                              formData,
                                              brands,
                                              submit
                                          }) {


    let brandsArray = brands?.map(val => {
        return val?.brand_name;
    });
    
    return (
        <Form.Group className="mb-3">
            <Autocomplete
                sx={!!validated && submit ? {border: '0.1px solid #AC1125', borderRadius: '5px'} : {width: '100%'}}
                multiple
                options={brandsArray}
                getOptionLabel={option => option}
                disableCloseOnSelect
                value={formData.brand ? formData.brand : []}
                onChange={(ev, val) => {
                    handleChange('brand',val.length ? val : '');
                }}
                renderInput={params => (
                    <TextField
                        {...params}
                        variant="outlined"
                    />
                )}
                renderOption={(props, option, {selected}) => (
                    <MenuItem
                        {...props}
                        key={option}
                        value={option}
                        sx={{justifyContent: 'space-between'}}
                    >
                        {option}
                        {selected ? <CheckIcon color="info"/> : null}
                    </MenuItem>
                )}
            />
        </Form.Group>
    );
}
