import React from "react";
import "./section_3.scss";

export default function Section_3({ title, text1, text2, text3, children }) {
    return (
        <div className="section_3">
            <div className="container">
                <h2 style={title ? {opacity: '1'} : {opacity: "0"}}>{title}</h2>
                <div className="section_3_content">
                    {text1 ? (
                        <p dangerouslySetInnerHTML={{ __html: text1 }} />
                    ) : null}
                    
                    {text3 ? (
                        <p
                            className="last-text"
                            dangerouslySetInnerHTML={{ __html: text3 }}
                        />
                    ) : null}
                    {children}
                    {text2 ? (
                        <p dangerouslySetInnerHTML={{ __html: text2 }} />
                    ) : null}
                </div>
            </div>
        </div>
    );
}
