const configs = {
	url: "https://phpstack-801806-2821690.cloudwaysapps.com/",
	// url: "http://localhost:3000",
	api: "https://phpstack-801806-2770896.cloudwaysapps.com",
	// adminApi: "http://localhost:4000",
	adminApi: 'https://server.fmx-appliance-repair.com',
	user: "emhsrtznsp",
	pass: "tjTeQ7Kg5P",
	db_name: "emhsrtznsp",
};

export default configs;