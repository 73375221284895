import React, { useState } from 'react'
import Form1 from "../components/form_1/Form1";
import { FaEnvelopeOpenText } from "react-icons/fa";
import { ThreeDots } from "react-loader-spinner";
import Section_3 from '../components/section/Section_3';
import Popup1 from '../components/popups/popup_1/Popup1';
import { ToastContainer } from 'react-toastify';

export default function Contact() {
   const [modal, setModal] = useState(false)
    const [loading, setLoading] = useState(false)

    return (
		<>
			<ToastContainer autoClose={2000} />
			{modal ? (
				<Popup1
					icon={<FaEnvelopeOpenText color="rgb(0 114 4)" />}
					text="Thank you for contacting us!"
					description="We will get in touch soon"
					setShow={setModal}
				/>
			) : null}
			{loading ? (
				<>
					<div
						className="popup_1_bg"
						onClick={(ev) => setLoading(false)}
					/>
					<div className="popup_1 loading">
						<ThreeDots color="#18435B" height={150} width={150} />
					</div>
				</>
			) : null}
			<Section_3 >
				<div className="container">
					<Form1 load={setLoading} setShow={setModal} />
				</div>
			</Section_3>
		</>
	);
}

