import React from "react";
import { MdArrowForwardIos, MdArrowBackIosNew } from "react-icons/md";
import Slider from "react-slick";
import './brand-slider.scss'
import configs from "../../api";

export default function Brands({ brands }) {
    const settings = {
        infinite: true,
        autoplay: true,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 1,
        initialSlide: 0,
        dots: false,
        nextArrow: <MdArrowForwardIos />,
        prevArrow: <MdArrowBackIosNew />,
        responsive: [
            {
                breakpoint: 1320,
                settings: {
                    slidesToShow: 6,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <div className="brands_slider">
            <Slider
                {...settings}
                className="carousel"
            >
                {brands && brands.map((brand) =>
                    // {console.log(`${configs.url}/public/images/brands/${brand.logo_image}`)}
                    (

                    <div key={brand.brand_id} className='brand_block'>
                         <img
                             style={{ width: "150px" }}
                             src={`${configs.url}/images/brands/${brand.logo_image}`}
                             alt={brand.logo_title}
                             title={brand.logo_title}
                         />
                     </div>
                 )
                )}
            </Slider>
        </div>
    );
}
