class validationClass {
	static first_name = (firstName) => {
		let validation = { status: "ok", errors: "" };
		if (firstName) {
			if (!/(^[a-zA-Z][a-zA-Z\s]{0,20}[a-zA-Z]$)/.test(firstName)) {
				validation.status = "error";
				validation.errors = "First Name field has invalid format";
			}
		} else {
			validation.status = "error";
			validation.errors =
				"All required fields marked in red should be filled";
		}
		return validation;
	};

	static last_name = (lastName) => {
		let validation = { status: "ok", errors: "" };
		if (lastName) {
			if (!/(^[a-zA-Z][a-zA-Z\s]{0,20}[a-zA-Z]$)/.test(lastName)) {
				validation.status = "error";
				validation.errors = "Last Name field has invalid format";
			}
		} else {
			validation.status = "error";
			validation.errors =
				"All required fields marked in red should be filled";
		}
		return validation;
	};
	static from_phoneNumber = (from_phoneNumber) => {
		let validation = { status: "ok", errors: "" };
		if (from_phoneNumber) {
			if (!/\+?[()0-9- ]{5,25}/.test(from_phoneNumber)) {
				validation.status = "error";
				validation.errors = "Phone number field has invalid format";
			}
		} else {
			validation.status = "error";
			validation.errors =
				"All required fields marked in red should be filled";
		}
		return validation;
	};
	static email = (email) => {
		let validation = { status: "ok", errors: "" };
		if (email) {
			if (
				!/^[a-zA-Z]+[a-zA-Z0-9._-]+[a-zA-Z0-9]@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
					email,
				)
			) {
				validation.status = "error";
				validation.errors = "Email field has invalid format";
			}
		} else {
			validation.status = "error";
			validation.errors =
				"All required fields marked in red should be filled";
		}
		return validation;
	};

	static phone_number = (phone) => {
		let validation = { status: "ok", errors: "" };
		if (phone) {
			if (!/\+?[()0-9- ]{5,25}/.test(phone)) {
				validation.status = "error";
				validation.errors = "Phone field has invalid format";
			}
		} else {
			validation.status = "error";
			validation.errors =
				"All required fields marked in red should be filled";
		}
		return validation;
	};

	static address = (subject) => {
		let validation = { status: "ok", errors: "" };
		if (subject) {
			if (!/^[^ ]{1}[a-zA-Z0-9\s,\-']*$/.test(subject))
				validation.status = "error";
			validation.errors = "Address field has invalid format";
		} else {
			validation.status = "error";
			validation.errors =
				"All required fields marked in red should be filled";
		}
		return validation;
	};
	c;
	static zip_code = (zip) => {
		let validation = { status: "ok", errors: "" };
		if (zip) {
			if (!/[0-9]{1,10}/.test(zip)) {
				validation.status = "error";
				validation.errors = "Zip Code field has invalid format";
			}
		} else {
			validation.status = "error";
			validation.errors =
				"All required fields marked in red should be filled";
		}
		return validation;
	};

	static description = (text) => {
		let validation = { status: "ok", errors: "" };
		if (!text) {
			validation.status = "error";
			validation.errors =
				"All required fields marked in red should be filled";
		}
		return validation;
	};

	static message = (message) => {
		let validation = { status: "ok", errors: "" };
		if (!message) {
			validation.status = "error";
			validation.errors =
				"All required fields marked in red should be filled";
		}
		return validation;
	};
	static city = (message) => {
		let validation = { status: "ok", errors: "" };
		if (!message) {
			validation.status = "error";
			validation.errors =
				"All required fields marked in red should be filled";
		}
		return validation;
	};
	static state = (message) => {
		let validation = { status: "ok", errors: "" };
		if (!message) {
			validation.status = "error";
			validation.errors =
				"All required fields marked in red should be filled";
		}
		return validation;
	};
	static appliance = (message) => {
		let validation = { status: "ok", errors: "" };
		if (!message) {
			validation.status = "error";
			validation.errors =
				"All required fields marked in red should be filled";
		}
		return validation;
	};
	static brand = (message) => {
		let validation = { status: "ok", errors: "" };
		if (!message) {
			validation.status = "error";
			validation.errors =
				"All required fields marked in red should be filled";
		}
		return validation;
	};
	static service_time = (message) => {
		let validation = { status: "ok", errors: "" };
		if (!message) {
			validation.status = "error";
			validation.errors =
				"All required fields marked in red should be filled";
		}
		return validation;
	};
	static service_date = (message) => {
		let validation = { status: "ok", errors: "" };
		if (!message) {
			validation.status = "error";
			validation.errors =
				"All required fields marked in red should be filled";
		}
		return validation;
	};
	static call_time = (message) => {
		let validation = { status: "ok", errors: "" };
		if (!message) {
			validation.status = "error";
			validation.errors =
				"All required fields marked in red should be filled";
		}
		return validation;
	};
}


export default validationClass;