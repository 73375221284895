import React, {useState} from 'react';
import "./form_1.scss";
import isEmpty from 'lodash/isEmpty'
import configs from "../../api";
import validation from "../../tools/validation";
import axios from "axios";
import _ from 'lodash'
import toast from "../Toast";
import {useParams} from "react-router-dom";

function Form1({setShow, load}) {
    const [errors, setErrors] = useState({})
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        description: '',
    })
    const [isHovered, setIsHovered] = useState(false);
    let { projectUrl } = useParams();
    let { color} = useParams();
    let { hoverColor} = useParams();

    const handleChange = (ev) => {
        setFormData(prevState => ({...prevState, [ev.target.name]: ev.target.value}))
        const val = validation[ev.target.name](ev.target.value)
        if (val?.status !== 'ok') {
            setErrors({...errors, [ev.target.name]: val.errors})
        } else {
            setErrors({...errors, [ev.target.name]: ''})
        }
    }
    const notify = React.useCallback((type, message) => {
        toast({type, message});
    }, []);

    const handleSubmit = async (ev) => {
        ev.preventDefault()
        let err = {};
        for (const i in formData) {
            if (validation[i]) {
                const v = validation[i](formData[i])
                err[i] = v.errors
                if (v.status !== 'ok') {
                    _.set(errors, i, v.errors)
                    setErrors({...errors})
                } else {
                    delete err[i]
                }
            }
        }
        if (!isEmpty(err)) {
            notify('error', err[Object.keys(err)[0]])
        }
        if (!isEmpty(err)) {
            return;
        }
        load(true)
        try {
            const resp = await axios.post(
                `${configs.api}/contact-us`,
                {
                    check: formData,
                },
                {
                    params: {
                        db_name: `${configs.db_name}`,
                        user: `${configs.user}`,
                        password: `${configs.pass}`,
                        source: window.location.hostname,
                    },
                },
            );
            if (resp.status === 200) {
                load(false)
                setShow(true)
                setFormData({
                    first_name: '',
                    last_name: '',
                    email: '',
                    phone_number: '',
                    description: '',
                });
            }
        } catch (e) {
            console.log(e)
        }

    }

    return (
        <div className="form_1_div">
            <form className="form_1" onSubmit={handleSubmit} noValidate>
                <div className="form_row">
                    <div className="form_column">
                        <label
                            className="form_label required"
                            htmlFor="first_name"
                        >
                            First Name
                        </label>
                        <input
                            style={
                                errors.first_name
                                    ? {border: "1px solid #AC1125"}
                                    : {border: "1px solid #B0B2B5"}
                            }
                            value={formData.first_name}
                            id="first_name"
                            name="first_name"
                            type="text"
                            onChange={(ev) => handleChange(ev)}
                        />
                    </div>
                    <div className="form_column">
                        <label
                            className="form_label required"
                            htmlFor="last_name"
                        >
                            Last Name
                        </label>
                        <input
                            style={
                                errors.last_name
                                    ? {border: "1px solid #AC1125"}
                                    : {border: "1px solid #B0B2B5"}
                            }
                            value={formData.last_name}
                            id="last_name"
                            name="last_name"
                            type="text"
                            onChange={(ev) => handleChange(ev)}
                        />
                    </div>
                </div>
                <div className="form_row">
                    <div className="form_column">
                        <label className="form_label required" htmlFor="email">
                            Email
                        </label>
                        <input
                            style={
                                errors.email
                                    ? {border: "1px solid #AC1125"}
                                    : {border: "1px solid #B0B2B5"}
                            }
                            value={formData.email}
                            id="email"
                            name="email"
                            type="text"
                            onChange={(ev) => handleChange(ev)}
                        />
                    </div>
                    <div className="form_column">
                        <label
                            className="form_label required"
                            htmlFor="phone_number"
                        >
                            Phone Number
                        </label>
                        <input
                            style={
                                errors.phone_number
                                    ? {border: "1px solid #AC1125"}
                                    : {border: "1px solid #B0B2B5"}
                            }
                            value={formData.phone_number}
                            id="phone_number"
                            name="phone_number"
                            type="text"
                            onChange={(ev) => handleChange(ev)}
                        />
                    </div>
                </div>
                <div className="form_textarea_row">
                    <div className="form_column">
                        <label
                            className="form_label required"
                            htmlFor="message"
                        >
                            Your Message
                        </label>
                        <textarea
                            style={
                                errors.description
                                    ? {border: "1px solid #AC1125"}
                                    : {border: "1px solid #B0B2B5"}
                            }
                            value={formData.description}
                            rows="5"
                            name="description"
                            id="message"
                            onChange={(ev) => handleChange(ev)}
                        />
                    </div>
                </div>
                <div className="form_btn_group">
                    <div
                        className="button_1_block"
                    >
                        <button type="submit" className="button_1"
                                style={{backgroundColor: isHovered ? `#${hoverColor}` : `#${color}`, transition: "background-color 0.3s ease", cursor: "pointer", color: 'white'}}
                                onMouseEnter={() => {setIsHovered(true)} }
                                onMouseLeave={() => {setIsHovered(false)}}>
                            Send
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default Form1;