import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import Schedule from './pages/Schedule';
import Contact from './pages/Contact';
import SendTxt from "./pages/SendTxt";
import RequestCallBack from "./pages/RequestCallBack";
import BrandsSlider from "./pages/Brands-Slider";

function App() {

    return (
        <BrowserRouter>
            <Routes>
                <Route
                    path="/"
                    element={<Schedule/>}
                />
                <Route
                    path="/:projectUrl"
                    element={<Schedule/>}
                />
                <Route
                    path="/:projectUrl/:color/:hoverColor"
                    element={<Schedule/>}
                />
                <Route path="/brands-slider" element={<BrandsSlider/>}/>
                <Route path="public/images/brands/*" element={<BrandsSlider/>}/>
                <Route path="images/brands/*" element={<BrandsSlider/>}/>
                <Route path="/contact-us" element={<Contact/>}/>
                <Route path="/contact-us/:color/:hoverColor" element={<Contact/>}/>
                <Route path="/send-txt" element={<SendTxt/>}/>
                <Route path="/send-txt/:projectUrl" element={<SendTxt/>}/>
                <Route path="/send-txt/:projectUrl/:color/:hoverColor" element={<SendTxt/>}/>
                <Route path="/callback" element={<RequestCallBack/>}/>
                <Route path="/callback/:projectUrl" element={<RequestCallBack/>}/>
                <Route path="/callback/:projectUrl/:color/:hoverColor" element={<RequestCallBack/>}/>
                <Route path="/*" element={<Navigate to="/404"/>}/>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
