import React, { useState } from "react";
import Section_3 from "../components/section/Section_3";
import Form_2 from "../components/form_2/Form_2";
import { FaCheckCircle } from "react-icons/fa";
import { ThreeDots } from "react-loader-spinner";
import Popup1 from "../components/popups/popup_1/Popup1";
import { ToastContainer } from "react-toastify";

export default function Schedule(props) {
	const [modal, setModal] = useState(false);
	const [loading, setLoading] = useState(false);

	return (
		<>
			<ToastContainer autoClose={2000} />
			{modal ? (
				<Popup1
					icon={<FaCheckCircle color="rgb(0 114 4)" />}
					text="Thank You For Choosing Us!"
					description={
						"Your Appliance Repair Service Request is successfully sent.<br/> We will get in touch soon"
					}
					setShow={setModal}
				/>
			) : null}
			{loading ? (
				<>
					<div
						className="popup_1_bg"
						onClick={(ev) => setLoading(false)}
					/>
					<div className="popup_1 loading">
						<ThreeDots color="#18435B" height={150} width={150} />
					</div>
				</>
			) : null}
			<Section_3 >
				<div className="container">
					<Form_2
						setShow={setModal}
						load={setLoading}
						services={props.services}
						brands={props.brands}
					/>
				</div>
			</Section_3>
		</>
	);
}

