import React from 'react';
import {TextField, Autocomplete, MenuItem} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import {Form} from 'react-bootstrap';
import '../multi_appliances/multi_appliances.scss'

export default function MultiSelectAppliances({
                                                  validated,
                                                  appliances,
                                                  handleChange,
                                                  formData,
                                                  submit
                                              }) {


    let appliancesArray = appliances?.map(val => {
        return val?.appliance_name;
    });

    return (
        <Form.Group className="mb-3">
            <Autocomplete
                sx={!!validated && submit ? {border: '0.1px solid #AC1125', borderRadius: '5px'} : {width: '100%'}}
                multiple
                options={appliancesArray}
                getOptionLabel={option => option}
                disableCloseOnSelect
                value={formData.appliance ? formData.appliance : []}
                onChange={(ev, val) => {
                    handleChange('appliance', val.length ? val : '');
                }}
                renderInput={params => (
                    <TextField
                        {...params}
                        variant="outlined"
                    />
                )}
                renderOption={(props, option, {selected}) => (
                    <MenuItem
                        {...props}
                        key={option}
                        value={option}
                        sx={{justifyContent: 'space-between'}}
                    >
                        {option}
                        {selected ? <CheckIcon color="info"/> : null}
                    </MenuItem>
                )}
            />
        </Form.Group>
    );
}
