import React, {useState} from 'react';
import "./form_4.scss";
import isEmpty from 'lodash/isEmpty'
import configs from "../../api";
import validation from "../../tools/validation";
import axios from "axios";
import _ from 'lodash'
import toast from "../Toast";
import {useParams} from "react-router-dom";

function Form4({setShow, load}) {
    const times = [
        {id: 1, title: "Morning"},
        {id: 2, title: "Noon"},
        {id: 3, title: "Afternoon"},
        {id: 4, title: "Evening"},
        {id: 5, title: "No Preference"},
    ];
    const [errors, setErrors] = useState({})
    const [isHovered, setIsHovered] = useState(false);
    let { projectUrl } = useParams();
    let { color} = useParams();
    let { hoverColor} = useParams();

    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        from_phoneNumber: '',
        call_time: '',
    })

    const handleChange = (ev, key, value) => {
        setFormData(prevState => ({...prevState, [key]: value}))
        const val = validation[ev.target.name](ev.target.value)
        if (val?.status !== 'ok') {
            setErrors({...errors, [ev.target.name]: val.errors})
        } else {
            setErrors({...errors, [ev.target.name]: ''})
        }
    }
    const notify = React.useCallback((type, message) => {
        toast({type, message});
    }, []);

    const handleSubmit = async (ev) => {
        ev.preventDefault()
        let err = {};
        for (const i in formData) {
            if (validation[i]) {
                const v = validation[i](formData[i]);
                err[i] = v.errors;
                if (v.status !== "ok") {
                    _.set(errors, i, v.errors);
                    setErrors({...errors});
                } else {
                    delete err[i];
                }
            }
        }
        if (!isEmpty(err)) {
            notify('error', err[Object.keys(err)[0]])
        }
        if (!isEmpty(err)) {
            return;
        }
        load(true)
        try {
            const createDatabase = await axios.post(
                `${configs.adminApi}/call_backs/add`,
                {
                    ...formData,
                    source: projectUrl,
                },
            );
            if(createDatabase.status === 200) {
                const resp = await axios.post(
                    `${configs.adminApi}/ringcentral/callback`,
                    {
                        ...formData,
                        source: projectUrl,
                    },
                );
                if (resp.status === 200) {
                    load(false)
                    setShow(true)
                    setFormData({
                        first_name: '',
                        last_name: '',
                        from_phoneNumber: '',
                        call_time: '',
                    });
                }
            }
        } catch (e) {
            console.log(e)
        }
    }


    return (
        <div className="form_4_div">
            <form className="form_4" onSubmit={handleSubmit} noValidate>
                <div className="form_4_row">
                    <div className="form_4_column">
                        <label
                            className="form_4_label required"
                            htmlFor="first_name"
                        >
                            First Name
                        </label>
                        <input
                            style={
                                errors.first_name
                                    ? {border: "1px solid #AC1125"}
                                    : {border: "1px solid #626262"}
                            }
                            value={formData.first_name}
                            id="first_name"
                            name="first_name"
                            type="text"
                            onChange={(ev) => handleChange(ev, 'first_name', ev.target.value)}
                        />
                    </div>
                    <div className="form_4_column">
                        <label
                            className="form_4_label required"
                            htmlFor="last_name"
                        >
                            Last Name
                        </label>
                        <input
                            style={
                                errors.last_name
                                    ? {border: "1px solid #AC1125"}
                                    : {border: "1px solid #626262"}
                            }
                            value={formData.last_name}
                            id="last_name"
                            name="last_name"
                            type="text"
                            onChange={(ev) => handleChange(ev, 'last_name', ev.target.value)}
                        />
                    </div>
                    <div className="form_4_column">
                        <label
                            className="form_4_label required"
                            htmlFor="from_phoneNumber"
                        >
                            Phone Number
                        </label>
                        <input
                            style={
                                errors.from_phoneNumber
                                    ? {border: "1px solid #AC1125"}
                                    : {border: "1px solid #626262"}
                            }
                            value={formData.from_phoneNumber}
                            id="from_phoneNumber"
                            name="from_phoneNumber"
                            type="text"
                            onChange={(ev) => handleChange(ev, 'from_phoneNumber', ev.target.value)}
                        />
                    </div>
                    <div className="input_block">
                        <label htmlFor="call_time" className="form_4_label required">
                            Best Time To Call
                        </label>
                        <select
                            style={
                                errors.call_time
                                    ? {border: "1px solid #AC1125"}
                                    : {border: "1px solid #626262"}
                            }
                            value={formData.call_time}
                            id="call_time"
                            className="inputEl"
                            onChange={(ev) => handleChange(ev, 'call_time', ev.target.value)}
                            name="call_time"
                            data-key="call_time"
                            required
                        >
                            <option value="">-- Select a Time --</option>
                            {times
                                ? times.map((time) => {
                                    return (
                                        <option
                                            key={time.id}
                                            value={time.title}
                                        >
                                            {time.title}
                                        </option>
                                    );
                                })
                                : null}
                        </select>
                    </div>
                </div>
                <div className="form_4_btn_group">
                    <div
                        className="button_4_block"
                    >
                        <button type="submit" className="button_4"
                                style={{backgroundColor: isHovered ? `#${hoverColor}` : `#${color}`, transition: "background-color 0.3s ease", cursor: "pointer", color: 'white'}}
                                onMouseEnter={() => {setIsHovered(true)} }
                                onMouseLeave={() => {setIsHovered(false)}}>
                            Send
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default Form4;