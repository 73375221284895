import React, {useEffect} from 'react';
import "./popup_1.scss";
import {AiOutlineClose} from 'react-icons/ai'


function Popup1({setShow, icon, text, description}) {

    useEffect(() => {
        setTimeout(() => {
            setShow(false)
        }, 3000)
    }, [])

    return (
        <>
            <div className='popup_1_bg' onClick={ev => setShow(false)}/>
            <div className="popup_1">
                <div className='popup_header'>
                    <div onClick={ev => setShow(false)}>
                        <AiOutlineClose  cursor='pointer' color='white' fontSize={25}/>
                    </div>
                </div>
                <div className='popup_content'>
                    <div className='popup_content_title'>
                        {icon}
                    </div>
                    <div className="popup_content_text">
                        <p className='popup_content_text_title'>
                            {text}
                        </p>
                        <p className='popup_content_text_description'  dangerouslySetInnerHTML={{ __html: description }}/>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Popup1;